/*
 *   File :  portfolio.js
 *   Author URI : https://evoqins.com
 *   Description : Container file which shows investments summary.
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import parse from 'html-react-parser';

import { BreadCrumb } from "../../Component/BreadCrumb"
import { InvestmentHeader } from "../../Component/Header"
import { RadioGroup } from "../../Component/Form-elements";
import { CustomTab } from "../../Component/Tab";
import { PortfolioSummaryCard } from "../../Component/Card";
import { GradientButton } from "../../Component/CTA";
import { PortfolioFundsTable, PortfolioGoalsTable } from "../../Component/Table";
import Footer from "../../Component/Footer/footer";
import APIService from "../../Service/api-service";
import { CustomLoader } from "../../Component/Other";
import { Icon } from "../../Component/Icon";

const PORTFOLIO_NAVIGATION = [
    { page: 'Home', url: '/' },
    { page: 'Portfolio', url: null },
]

const PORTFOLIO = [
    {
        id: -1, label: 'All',
        image: require("../../Assets/Images/portfolio/all.png")
    },
    {
        id: 1, label: 'mt',
        image: require("../../Assets/Images/portfolio/mt.png")
    },
    {
        id: 2, label: 'non-mt',
        image: require("../../Assets/Images/portfolio/non-mt.png")
    },
];

const TAB_DATA = ['Lumpsum', 'SIP'];

const INVESTMENT_DATA = ["Mutual funds", "Life goals"];


const Portfolio = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const TOOL_TIP = useSelector(state => state.generalData.TOOL_TIP_DATA);
    const [portfolioRadio, setPortFolioRadio] = useState(PORTFOLIO[0].id);
    const [tabIndex, setTabIndex] = useState(1);
    const [investmentType, setInvestmentType] = useState(1);
    const [portfolioData, setPortfolioData] = useState({});
    const [loader, setLoader] = useState(true);
    const [tabData, setTabData] = useState(INVESTMENT_DATA);
    const [tableDataLoader, setTableDataLoader] = useState(true);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        _getPortfolioData();
        if (portfolioRadio === 2) {
            setTabData(['Mutual funds']);
        } else {
            setTabData(INVESTMENT_DATA);
        }
    }, [portfolioRadio]);

    function _handleRadio(e) {
        setPortFolioRadio(e);
        if (location.state) {
            navigate(location.pathname, {})
        }
    }

    // handler for tab change
    const _handleTabSelect = (type, index) => {
        switch (type) {
            case 1:
                setTabIndex(index);
                break;
            case 2:
                setInvestmentType(index);
                break;
        }
    }

    const _handleNavigate = (type, id) => {
        switch (type) {
            case 1:
                navigate("mutual-fund-detail", {
                    state: { id: id, is_goal: false }
                });
                break;
            case 2:
                navigate("/orders", { state: { order_status: 1 } });
                break;
            case 3:
                navigate("goal-fund-detail", {
                    state: { id: id, is_goal: true }
                });
                break;
        }
    }


    // API - get portfolio data
    const _getPortfolioData = () => {
        setTableDataLoader(true);
        let url = 'portfolio/get';
        let payload = JSON.stringify({
            type: portfolioRadio
        })
        APIService(true, url, payload, true).then((response) => {
            if (response.status_code === 200) {
                setPortfolioData(response.data);

                setLoader(false);
                const { state } = location;
                if (state) {
                    if (state.show_imported_fund === true) {
                        _showNoMtFunds();
                    }
                }
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
            setTableDataLoader(false);
        })

    }

    function _showNoMtFunds() {
        setPortFolioRadio(2);
        var detail_section = document.getElementById('detail-section');
        if (detail_section) {
            detail_section.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const PendingTransaction = (props) => {
        return (
            <div className="border-radius-16px bg-pale-lavender w-mobile-100">
                <div className="d-flex gap-8px align-items-center p-3 border-bottom-1px border-lavender-blue">
                    <img src={props.image}
                        alt={props.title}
                        width={24}
                        height={24} />
                    <p className="color-black e-poppins-medium e-font-16 line-height-20px mb-0">{props.title}</p>
                </div>
                <div className="row">
                    <div className="col-6 text-center border-right-1px-lavender-blue pe-0">
                        <div className="py-2 padding-18px-lr">
                            <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-1">{props.item.no_of_orders}</p>
                            <p className="color-outer-space e-poppins-medium e-font-14 line-height-20px mb-0 ">Total orders</p>
                        </div>
                    </div>
                    <div className="col-6  text-center ps-0">
                        <div className="py-2 padding-18px-lr">
                            <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-1"><span className="e-inter-medium">₹</span>{props.item.amount.toLocaleString("en-IN")}</p>
                            <p className="color-outer-space e-poppins-medium e-font-14 line-height-20px mb-0 white-space-nowrap">Approx. amt</p>
                        </div>
                    </div>
                </div>

            </div>

        )
    }

    return (

        <Fragment>
            <div className="e-portfolio e-page-container margin-72px-bottom">
                <InvestmentHeader />
                {
                    loader === true ?
                        <div className="h-80vh">
                            <CustomLoader />
                        </div>
                        :
                        <>
                            <BreadCrumb data={PORTFOLIO_NAVIGATION} />
                            <div className="row">
                                <div className="col-xl-7 col-lg-7 col-md-6 col-12">
                                    <div className="d-lg-flex d-md-flex d-sm-flex d-block flex-wrap justify-content-between align-items-center mb-3 mb-lg-0 mb-md-0 mb-sm-0">
                                        <p className="color-black e-poppins-medium e-font-20 line-height-32px mt-4 mb-3 me-3 margin-block-end-0">Portfolio</p>
                                        <div className="mt-2">
                                            <RadioGroup data={PORTFOLIO}
                                                className="gap-sm-16px-8px d-lg-flex d-md-flex d-sm-flex d-block flex-nowrap"
                                                radioItemClassName="py-2"
                                                size={20}
                                                fontSize="e-font-sm-16-14"
                                                type={3}
                                                id={portfolioRadio}
                                                radioHandler={_handleRadio} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-sm-2 mt-0">
                                <div className="col-xl-7 col-lg-7 col-md-6 col-12">
                                    <PortfolioSummaryCard data={portfolioData.portfolio.self_details}
                                        isFundImported={portfolioData.is_cas_imported}
                                        lastCASImport={portfolioData.last_cas_import}
                                        isUpdateAvailable={portfolioData.is_cas_import_allowed}
                                        callBackExternalFunds={_showNoMtFunds} />
                                </div>

                                <div className="col-xl-5 col-lg-5 col-md-6 col-12 pt-md-0 pt-sm-4 pt-3" >
                                    <div className="border-radius-16px px-sm-4 px-3 py-3 bg-investment-breakdown h-100">
                                        <p className="color-black e-poppins-semi-bold e-font-16 line-height-20px mb-3 mt-2 d-flex">
                                            Investment breakdown
                                            {
                                                Object.keys(TOOL_TIP).length !== 0 && TOOL_TIP.investment_breakdown &&
                                                <div className="position-relative">
                                                    <Icon icon="info"
                                                        size={20}
                                                        className="cursor-pointer ms-2"
                                                        data-tooltip-id="card-title-tooltip" />
                                                    <Tooltip id="card-title-tooltip"
                                                        arrowColor='transparent'
                                                        effect="float"
                                                        place="bottom"
                                                        className='e-tooltip-general'>
                                                        {parse(TOOL_TIP.investment_breakdown)}
                                                    </Tooltip>
                                                </div>
                                            }

                                        </p>
                                        <div className="py-2 px-3 bg-cards border-radius-24px ">
                                            <CustomTab data={TAB_DATA}
                                                wrapperClass={'gap-16px'}
                                                className="px-0 padding-10px-tb e-order-tab mb-4"
                                                onSelectTab={(value) => _handleTabSelect(1, value)} />

                                            <div className="row">
                                                {
                                                    tabIndex === 2 &&
                                                    <div className="col-4 mt-2">
                                                        <div className="p-2">
                                                            <p className="color-outer-space e-poppins-medium e-font-14 line-height-14px mb-2">Active SIP</p>
                                                            <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0">{portfolioData.investment_breakdown.sip.sip_count}</p>
                                                        </div>
                                                    </div>
                                                }

                                                <div className="col-4 mt-2">
                                                    <div className="p-2">
                                                        <p className="color-outer-space e-poppins-medium e-font-14 line-height-14px mb-2">Invested</p>
                                                        <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0"><span className="e-inter-medium">₹</span>{tabIndex === 2 ? portfolioData.investment_breakdown.sip.invested.toLocaleString("en-IN") : portfolioData.investment_breakdown.lumpsum.invested.toLocaleString("en-IN")}</p>
                                                    </div>
                                                </div>
                                                <div className="col-4 mt-2">
                                                    <div className="p-2">
                                                        <p className="color-outer-space e-poppins-medium e-font-14 line-height-14px mb-2">Current</p>
                                                        <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0"><span className="e-inter-medium">₹</span>{tabIndex === 2 ? portfolioData.investment_breakdown.sip.current.toLocaleString("en-IN") : portfolioData.investment_breakdown.lumpsum.current.toLocaleString("en-IN")}</p>
                                                    </div>
                                                </div>
                                                <div className="col-4 mt-2">
                                                    <div className="p-2">
                                                        <p className="color-outer-space e-poppins-medium e-font-14 line-height-14px mb-2">Returns</p>
                                                        <p className={`color-black e-poppins-medium e-font-16 line-height-16px mb-0 ${tabIndex === 2 ? portfolioData.investment_breakdown.sip.returns < 0 ? "color-red" : "color-sea-green" : portfolioData.investment_breakdown.lumpsum.returns < 0 ? "color-red" : "color-sea-green"} e-poppins-medium e-font-14 line-height-14px mb-0`}><span className="e-inter-medium">₹</span>{tabIndex === 2 ? portfolioData.investment_breakdown.sip.returns.toLocaleString("en-IN") : portfolioData.investment_breakdown.lumpsum.returns.toLocaleString("en-IN")}</p>
                                                    </div>
                                                </div>
                                                <div className="col-4 mt-2">
                                                    <div className="p-2">
                                                        <p className="color-outer-space e-poppins-medium e-font-14 line-height-14px mb-2">Return %</p>
                                                        {
                                                            tabIndex === 2 ?
                                                                <p className={`${portfolioData.investment_breakdown.sip.returns_percentage < 0 ? "color-red" : "color-sea-green"} e-poppins-medium e-font-14 line-height-14px mb-0`}>{portfolioData.investment_breakdown.sip.returns_percentage.toLocaleString("en-IN")}%</p>
                                                                :
                                                                <p className={`${portfolioData.investment_breakdown.lumpsum.returns_percentage < 0 ? "color-red" : "color-sea-green"} e-poppins-medium e-font-14 line-height-14px mb-0`}>{portfolioData.investment_breakdown.lumpsum.returns_percentage.toLocaleString("en-IN")}%</p>
                                                        }
                                                    </div>
                                                </div>
                                                {/* {
                                            tabIndex === 1 && */}
                                                <div className="col-4 mt-2">
                                                    <div className="p-2">
                                                        <p className="color-outer-space e-poppins-medium e-font-14 line-height-14px mb-2">XIRR</p>
                                                        <p className={`${portfolioData.investment_breakdown.sip.xirr < 0 ? "color-red" : "color-sea-green"} e-poppins-medium e-font-14 line-height-14px mb-0`}>{tabIndex === 2 ? portfolioData.investment_breakdown.sip.xirr : portfolioData.investment_breakdown.lumpsum.xirr}%</p>
                                                    </div>
                                                </div>
                                                {/* } */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* pending amount */}
                            {
                                portfolioData.pending_orders.amount !== 0 &&
                                <div className="border-radius-16px bg-lavender p-sm-4 p-3 margin-32px-top-sm-16 position-relative">
                                    <div className="d-flex flex-wrap align-items-center gap-24px">
                                        <div>
                                            <p className="color-black e-poppins-semi-bold e-font-16 margin-10px-bottom">Pending transaction</p>
                                            <div className="d-flex align-items-center flex-wrap gap-8px e-pending-transaction-card">
                                                <p className="color-primary-color e-poppins-bold e-font-24 line-height-24px mb-0 me-1"><span className="e-inter-bold">₹</span>{portfolioData.pending_orders.amount.toLocaleString('en-IN')}</p>
                                                <p className="color-outer-space e-poppins-medium e-font-12 line-height-20px mb-0 ">(To receive reflection, wait for T+2 days for equity based schemes and T+1 for debt based schemes)</p>
                                            </div>
                                            <GradientButton label="View orders"
                                                className="px-3 py-2 margin-14px-top"
                                                handleClick={() => _handleNavigate(2)} />
                                        </div>
                                        <PendingTransaction item={portfolioData.pending_orders.purchase}
                                            image={require("../../Assets/Images/portfolio/purchase-order.png")}
                                            title="Purchase orders" />
                                        <PendingTransaction item={portfolioData.pending_orders.withdrawal}
                                            image={require("../../Assets/Images/portfolio/withdrawal-order.png")}
                                            title="Withdrawal orders" />
                                    </div>
                                    <img src={require("../../Assets/Images/portfolio/transaction-bg-image.svg").default}
                                        alt="Pending transaction"
                                        className="position-absolute right-0 top-0 border-radius-right-16px" />
                                </div>
                            }

                            <p className="color-jett-black e-poppins-medium e-font-16 line-height-24px mb-sm-3 mb-0 mt-sm-4 mt-3">Investment details</p>

                            <CustomTab data={tabData}
                                wrapperClass={'gap-16px'}
                                className="px-0 padding-10px-tb e-order-tab margin-28px-bottom"
                                onSelectTab={(value) => _handleTabSelect(2, value)} />
                            {
                                tableDataLoader === true ?
                                    <div className="">
                                        <CustomLoader />
                                    </div>
                                    :
                                    <>
                                        {
                                            portfolioData.investment_details.mutual_fund.length === 0 && portfolioData.investment_details.life_goals.length === 0 ?
                                                null
                                                :
                                                <div className="col-12 text-end d-sm-block d-none mt-sm-2 mt-0 e-table-scroll-indicator-watchlist">
                                                    <p className="color-outer-space e-poppins-regular e-font-12 mb-0">
                                                        <img draggable={false} src={require('../../../src/Assets/Images/explore/scroll-gif.gif')} alt="scroll"
                                                            width='24px'
                                                            height='24px'
                                                            className='e-rotate-gif e-filter-invert-1' />
                                                        Scroll horizontally for view more  information</p>
                                                </div>
                                        }

                                        {
                                            investmentType === 1 ?
                                                <div id="detail-section">
                                                    <PortfolioFundsTable data={portfolioData.investment_details.mutual_fund}
                                                        onClick={(id) => _handleNavigate(1, id)}
                                                        selectedFund={portfolioRadio} />
                                                </div>
                                                :
                                                <PortfolioGoalsTable data={portfolioData.investment_details.life_goals}
                                                    onClick={(id) => _handleNavigate(3, id)} />
                                        }
                                    </>
                            }

                        </>
                }

            </div>

            <Footer />
        </Fragment>
    )
}

export default Portfolio 