/*
 *   File : payment.js
 *   Author URI : www.evoqins.com
 *   Description : Modal for make payment of pending payment
 *   Integrations : null
 *   Version : v1.0
 *   Created : 15-01-2024
 */

import { useEffect, useRef, useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

import style from '../../Styles/Component/pending-payment.module.scss';
import { GradientButton, SecondaryButton } from '../CTA';
import Icon from '../Icon/icon';
import { OTPInput, CustomTextInput, RadioGroup } from '../Form-elements';
import { _consent2FA } from '../../Helper/api';

import OrderAPIService from '../../Service/order-api';
import Colors from '../../Styles/color.module.scss';

import successIcon from '../../Assets/Images/profile/success.svg';
import failureIcon from '../../Assets/Images/orders/order-failed.svg';

const SUCCESS_MESSAGE = 'Payment successful! Your transaction has been completed, thank you for your purchase.';
const FAILED_MESSAGE = 'Payment failed. Please check your payment details and try again or contact customer support for assistance.';
const SUCCESS_TITLE = 'Your payment is successful';
const FAILED_TITLE = 'Your payment has failed';
const REDEEM_SUCCESS_TITLE = 'Redeem successful';
const REDEEM_SUCCESS_MESSAGE = 'You have successfully redeemed';

const SUCCESS = {
    title: 'Order successful',
    message: 'Your order has been successfully placed'
};

const FAIL = {
    title: 'Order failed',
    message: 'We regret to inform you that your recent order has failed.'
};

const PAYMENT_PENDING = {
    title: 'Payment pending',
    message: 'Payment is pending. Please approve payment to place order.'
}

const PROCESSING = {
    title: 'Processing',
    message: 'Payment completed. Your order status will reflect after some time.'
}

const MANDATE_SUCCESS = {
    title: 'Mandate creation under process',
    message: 'Please check the status after some time'
}

const MANDATE_FAILED = {
    title: 'Mandate failed',
    message: 'We regret to inform you that your recent mandate creation has failed.'
}

const SIP_SUCCESS = {
    title: 'SIP created',
    message: 'Your SIP has been successfully created'
}

const SIP_FAILED = {
    title: 'SIP failed',
    message: 'We regret to inform you that your recent SIP has failed'
}

const PAYMENT_TYPE = [
    { id: 2, label: 'Net banking' },
    { id: 1, label: 'UPI' },
    { id: 3, label: 'NEFT/RTGS' }
]


const PendingPayments = (props) => {

    var timer;
    const paymentPinRef = useRef(null);
    const navigator = useNavigate();

    const [pin, setPin] = useState("");
    const [validationError, setValidationError] = useState("");
    const [focusedInput, setFocusedInput] = useState(1);
    const [apiLoader, setApiLoader] = useState(false);
    const [renderedScreen, setRenderedScreen] = useState(0);
    const [verifyUsingUPI, setVerifyUsingUPI] = useState(true);
    const [paymentStatus, setPaymentStatus] = useState(true);
    const [upiId, setUpiId] = useState('');
    const [upiIdError, setUpiIdError] = useState('');
    const [upiTimerSecond, setUpiTimerSecond] = useState(600);
    const [paymentId, setPaymentId] = useState(null);
    const [paymentStatusText, setPaymentStatusText] = useState("");
    const [paymentInfo, setPaymentInfo] = useState({ title: "", message: "" });
    const [paymentStatusType, setPaymentStatusType] = useState(1);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(1);
    const [seconds, setSeconds] = useState(30);
    const [utrNumber, setUtrNumber] = useState("");
    const [utrNumberError, setUtrNumberError] = useState("");
    const [openUTRInstruction, setOpenUTRInstruction] = useState(false);
    const [showUTRInstructions, setShowUTRInstructions] = useState(false);

    useEffect(() => {
        const { close } = props;
        var my_modal = document.getElementById("payment-modal");
        my_modal.addEventListener("hidden.bs.modal", () => {
            close();
        });
    }, [props]);

    useEffect(() => {
        const countdown = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            } else {
                clearInterval(countdown);
                // Handle expiration logic here (e.g., show a resend button)
            }
        }, 1000);

        return () => {
            clearInterval(countdown);
        };
    }, [seconds]);

    useEffect(() => {
        if (renderedScreen === 1) {
            _checkUPIStatus();
            const interval = setInterval(() => {
                setUpiTimerSecond(prevSeconds => {
                    if (prevSeconds === 0) {
                        clearInterval(interval);
                        setPaymentStatus(false);
                        setRenderedScreen(2);
                    }
                    return prevSeconds - 1;
                });
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [renderedScreen]);

    useEffect(() => {
        if (paymentStatusText !== '') {
            switch (paymentStatusText) {
                case 'Success': setPaymentInfo(selectedPaymentMethod === 1 ? SUCCESS : PROCESSING);
                    setPaymentStatusType(1);
                    break;
                case 'Failed': setPaymentInfo(FAIL);
                    setPaymentStatusType(3);
                    break;
                case 'Payment pending': setPaymentInfo(PAYMENT_PENDING);
                    setPaymentStatusType(2);
                    break;
                case 'Processing': setPaymentInfo(PROCESSING);
                    setPaymentStatusType(2);
                    break;

            }
        }

    }, [paymentStatusText, paymentStatus]);

    function _checkUPIStatus() {
        let url = 'payment/poll-status';
        let payload = JSON.stringify({
            payment_id: paymentId
        });
        OrderAPIService(true, url, payload, props.validUser).then((response) => {
            if (response.status_code === 200) {
                if (response.data.payment_status_txt === 'pending') {
                    setTimeout(_checkUPIStatus, 1000);
                } else {
                    _paymentCallback(paymentId);
                }
            }
        })
    }
    // handle pin change
    const _handlePin = (value) => {
        setPin(value);
        setValidationError("");
    }

    // pin submit form 
    function _handlePinFormSubmit(e) {
        e.preventDefault();
    }

    // handle UTR instruction link
    function _openUTRInstructionModal() {
        setShowUTRInstructions(true);
    }

    function _closeUTRInstruction() {
        setShowUTRInstructions(false);
    }

    function _navigateBack() {
        setRenderedScreen(renderedScreen - 1);
    }


    function _viewOrderClick() {
        window.location.href = '/orders';
    }

    function _handleModalClose() {
        props.successCallBack();
    }

    function _openPaymentURL(html, url, payment_id) {

        var new_window;

        // In production we expecting string of html and convert given string as html and opening in new window
        // In other env we expecting url and directly open it in new window
        if (process.env.REACT_APP_ENV == 'PROD') {
            const winUrl = URL.createObjectURL(
                new Blob([html], { type: "text/html" })
            );
            new_window = window.open(
                winUrl,
                "win",
                `width=800,height=800,screenX=200,screenY=200`
            );
        } else {
            new_window = window.open(url, '_blank', 'width=800,height=800');
        }


        if (new_window !== null) {
            new_window.opener = null;
            timer = setInterval(function () {
                if (new_window.closed) {
                    clearInterval(timer);
                    _paymentCallback(payment_id);
                }
            }, 1000);
        } else {
            setApiLoader(false);
            toast.dismiss();
            toast.error('Popups are disabled in your browser. Please allow popups to open the payment gateway.', {
                type: 'error'
            });
        }

    }

    // close modal 
    function _closeModal() {
        let button = document.getElementById('close-modal');
        button.click();
    }

    // Initiate payment 
    function _initiatePayment() {

        if (pin.length !== 4) {
            setValidationError('Invalid OTP number');
            return;
        }
        const generate_today = props.validUser === true ? props.data.summary.generate_today : props.data.generate_today
        if (props.redeem !== true && generate_today === true) {
            if (selectedPaymentMethod === 1 && upiId === '') {
                setUpiIdError('UPI Id is required');
                return;
            } else if (selectedPaymentMethod === 1 && upiId.includes("@") === false) {
                setUpiIdError('Invalid UPI Id');
                return;
            }
            if (selectedPaymentMethod === 3 && utrNumber === "") {
                setUtrNumberError("UTR Number is required");
                return;
            }
        }

        setApiLoader(true);
        let url = 'order/confirm';
        let payload = JSON.stringify({
            order_ids: props.validUser === true ? props.id : props.data.order_ids,
            payment_method: props.redeem === true ? "" : selectedPaymentMethod === 1 ? 'UPI' : selectedPaymentMethod === 2 ? 'NETBANKING' : "NEFT_RTGS",
            otp: pin,
            upi_id: upiId,
            neft_number: utrNumber
        });
        OrderAPIService(true, url, payload, props.validUser).then((response) => {
            if (response.status_code === 200) {
                if (props.redeem === true || props.data?.generate_today === false) {
                    setPaymentStatus(true);
                    setPaymentStatusType(1);
                    setPaymentInfo(SUCCESS);
                    setRenderedScreen(2);
                } else {
                    if (selectedPaymentMethod === 1) {
                        setRenderedScreen(1);
                    } else {
                        if (response.data.payment_html !== null) {
                            _openPaymentURL(response.data.payment_html, response.data.payment_url, response.data.payment_id);
                        }
                        else {
                            _paymentCallback(response.data.payment_id);
                        }
                    }
                }
                setPaymentId(response.data.payment_id);

            } else {
                setApiLoader(false);
                if (response.status_code === 401) {
                    _closeModal();
                    props.handleInvalidToken();
                }
                toast.error(response.message, {
                    type: 'error'
                });
            }
        })
    }

    function _paymentCallback(payment_id) {
        let url = 'payment/check-status';
        let payload = JSON.stringify({
            payment_id
        });
        OrderAPIService(true, url, payload, props.validUser).then((response) => {
            if (response.status_code === 200) {
                setPaymentStatus(response.data.payment_status);
                setPaymentStatusText(response.data.payment_status_txt)
                setRenderedScreen(2);
            }
        })

    }

    function _handleUPIInput(value) {
        setUpiId(value);
        setUpiIdError('');
    }

    function _handleUTRChange(value) {
        setUtrNumber(value);
        setUtrNumberError("");
    }

    const _handlePaymentRadio = (id) => {
        setSelectedPaymentMethod(id);
    }


    function _handleResendOTP() {
        if (props.adminOrder === true) {
            let url = '2FA/get-otp';
            let payload = JSON.stringify({
                type: "ORDER",
                order_ids: [props.data.order_id]
            });
            OrderAPIService(true, url, payload).then((response) => {
                if (response.status_code === 200) {
                    toast.dismiss();
                    toast.success("An OTP has been sent to your registered phone number", {
                        type: "success",
                    });
                    setSeconds(30);
                    setPin("");
                } else {
                    toast.dismiss();
                    toast.error(response, {
                        type: "error"
                    });
                }
            })

        } else {
            _consent2FA("ORDER", props.id)
                .then((response) => {
                    toast.dismiss();
                    toast.success("An OTP has been sent to your registered phone number", {
                        type: "success",
                    });
                    setSeconds(30);
                    setPin("");
                }).catch((response) => {
                    toast.dismiss();
                    toast.error(response, {
                        type: "error"
                    });
                });
        }
    }

    // show payment status 
    function PaymentStatus({ title, content, icon }) {
        return (
            <>
                <div className='d-flex flex-column align-items-center text-center'>
                    <img src={paymentStatusType === 1 ? require('../../Assets/Images/orders/order-success.svg').default : paymentStatusType === 2 ? require('../../Assets/Images/orders/payment-process.svg').default : require('../../Assets/Images/orders/order-failed.svg').default}
                        alt="Pause SIP"
                        width={72}
                        height={72} />
                    <p className='color-black e-poppins-medium e-font-18 mb-2 mt-4'>{paymentInfo.title}</p>
                    <p className='color-black e-poppins-regular e-font-14 mb-0'>
                        {paymentInfo.message}
                    </p>
                </div>
                <div className='d-flex justify-content-center mt-5'>
                    <SecondaryButton label="Close"
                        className="me-3 color-black"
                        cancel="modal"
                        handleClick={_handleModalClose} />

                    <GradientButton label={Cookies.get('refreshToken') ? "View order" : "Login"}
                        className="padding-64px-lr padding-14px-tb"
                        handleClick={_viewOrderClick} />
                </div>
            </>
        )
    }

    // Select Payment method screen 
    function PaymentMethod(props) {
        const { title, desc, upi, recommended } = props;
        return (
            <div className={`${props.className}`}
                onClick={() => props.handleClick()}>

                {/* Check icon */}
                <Icon icon={props.icon}
                    size={22}
                    className={`position-absolute cursor-pointer ${style.radio_icon} right-10px`} />

                <div className="row">
                    <div className="col-12 d-flex ">
                        {/* Card title */}
                        <h4 className="color-black e-poppins-semi-bold e-font-16 margin-block-end-0 line-height-normal me-2 mt-1">
                            {title}
                        </h4>
                        {/* UPI icon */}
                        <img src={props.image}
                            alt=""
                            className={`${style.e_upi_icon} me-2`} />
                        {/* Recommended badge */}
                        {
                            recommended === true && (
                                <img src={require('../../Assets/Images/recommended.svg').default}
                                    alt="" />
                            )
                        }
                    </div>
                    {/* description */}
                    <div className="col-12 text-start">
                        <p className="e-inter-regular e-font-14 color-outer-space margin-block-end-0 mt-2">{desc}</p>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={`modal fade ${style.e_unlock_modal}`}
            id="payment-modal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="unlock-modal"
            aria-hidden="true">
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_unlock_dialogue}`}>
                <div className={`modal-content ${style.e_payment_content} position-relative`}>
                    <span id="close-modal" className='d-none' data-bs-toggle="modal" data-bs-target="#payment-modal"></span>
                    <Icon icon="close"
                        size={24}
                        data-bs-dismiss="modal"
                        className="mb-3 cursor-pointer e-modal-close-btn position-absolute right-20px top-20px"
                        onClick={_handleModalClose} />
                    {
                        showUTRInstructions === true ?
                            <div className={`${style.e_modal_body}`}>
                                <div className={`d-flex position-relative`}>
                                    <Icon icon="arrow-right1"
                                        size={24}
                                        color={Colors.black}
                                        className={`cursor-pointer ${style.e_back}`}
                                        onClick={_closeUTRInstruction} />
                                    <p className="color-eerie-black ms-2 e-poppins-medium e-font-16 line-height-16px mb-0 mt-1">
                                        NEFT/RTGS details
                                    </p>
                                </div>
                                <div className="overflow-auto e-scroll-container mt-3">
                                    <div className="col-12">
                                        <p className="color-black e-poppins-regular e-font-16">
                                            Please add the following account in your bank as beneficiary for NEFT/RTGS payments.
                                            Once the beneficiary is setup, you can place and pay for your orders in the following manner.</p>

                                        <ol>
                                            <li className="e-poppins-medium e-font-16 color-black">
                                                {"Individual /Savings Account - Fund Transfer > Transfer to eCMS Account > Third Party Transfer > Add a Beneficiary"}
                                            </li>
                                            <li className="e-poppins-medium e-font-16 color-black mt-3">
                                                {"Non-Individual /Current Account - Fund Transfer > Request > Add a Beneficiary > Transfer to eCMS account"}
                                            </li>
                                        </ol>
                                    </div>

                                    <div className="col-12 text-center">
                                        <p className="e-poppins-medium e-font-14 color-black">Beneficiary Details to be added for NEFT/RTGS transactions</p>
                                    </div>
                                    <div className="col-12">
                                        <table className="utr-instruction-table">
                                            <tr>
                                                <td>Beneficiary Name</td>
                                                <td>Indian Clearing Corporation Limited-MFD A/c</td>
                                            </tr>
                                            <tr>
                                                <td>Beneficiary a/c no</td>
                                                <td>MFDICC</td>
                                            </tr>
                                            <tr>
                                                <td>IFSC Code</td>
                                                <td>HDFC0000240</td>
                                            </tr>
                                            <tr>
                                                <td>Account type</td>
                                                <td>Current</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="col-12 text-center mt-3">
                                        <p className="e-poppins-medium e-font-14 color-bright-red">Only NEFT/RTGS model is supported. Do not transfer using IMPS or UPI mode.</p>
                                    </div>
                                    <div className="col-8 offset-2">
                                        <GradientButton label="I understood"
                                            className="e-font-16 padding-12px-tb w-100"
                                            handleClick={_closeUTRInstruction} />
                                    </div>
                                </div>
                            </div>
                            :
                            <>
                                {
                                    renderedScreen === 0 ? (
                                        <div className={style.e_modal_body}>
                                            <div className='d-flex flex-column align-items-center text-center'>
                                                <img src={require('../../Assets/Images/payment-pin.svg').default}
                                                    alt="Pin icon"
                                                    draggable={false}
                                                    width={72}
                                                    height={72} />
                                                <p className='color-black e-poppins-medium e-font-18 mb-2 mt-4'>Two factor authentication</p>
                                                <p className='color-black e-poppins-regular e-font-14 mb-0'>
                                                    Two-factor authentication for payment requests improves security by adding an additional layer of verification for transaction authorization.
                                                </p>
                                            </div>
                                            <form onSubmit={_handlePinFormSubmit}>

                                                <OTPInput pinString={pin}
                                                    id="pending-payment-pin"
                                                    autoFocus={true}
                                                    pinError={validationError}
                                                    className='mt-4'
                                                    handleChange={_handlePin} />

                                            </form>
                                            {
                                                props.redeem !== true && props.data?.generate_today !== false &&
                                                <div className="row mt-4">
                                                    <h4 className="color-black e-poppins-medium e-font-16 cursor-pointer w-max-content mt-1 mb-4"
                                                        onClick={_navigateBack}
                                                    >
                                                        Select payment method
                                                    </h4>
                                                    <p className='color-outer-space e-poppins-regular e-font-14'>Select your preferred payment method to proceed with the transaction.</p>
                                                    {/* Verify using UPI card */}
                                                    <div className=" col-12  ">
                                                        <RadioGroup data={PAYMENT_TYPE}
                                                            radioItemClassName="py-2"
                                                            size={14}
                                                            fontSize="e-font-12"
                                                            type={2}
                                                            id={selectedPaymentMethod}
                                                            className="py-2"
                                                            radioHandler={_handlePaymentRadio} />
                                                    </div>

                                                    {
                                                        selectedPaymentMethod === 1 &&
                                                        <div className='col-12 mt-4' data-disabled={apiLoader}>
                                                            <CustomTextInput value={upiId}
                                                                key={"UPI ID"}
                                                                label="ADD UPI ID*"
                                                                placeholder="sample@upi"
                                                                error={upiIdError}
                                                                inputClass=" bg-transparent e-poppins-semi-bold padding-28px-left py-3 border-radius-12px"
                                                                labelClass="bg-modal e-font-16 line-height-26px"
                                                                handleChange={_handleUPIInput} />
                                                        </div>
                                                    }

                                                    {
                                                        selectedPaymentMethod === 3 &&
                                                        <div className="col-12 mt-4">
                                                            <CustomTextInput value={utrNumber}
                                                                key={"UTR ID"}
                                                                label="Enter UTR number"
                                                                placeholder="Add UTR number"
                                                                error={utrNumberError}
                                                                inputClass=" bg-transparent e-poppins-semi-bold padding-28px-left py-3 border-radius-12px"
                                                                labelClass="bg-modal e-font-16 line-height-26px"
                                                                handleChange={_handleUTRChange} />
                                                        </div>
                                                    }
                                                    {
                                                            selectedPaymentMethod === 3 &&
                                                            <div className="col-12 mt-3">
                                                                <p className="color-black e-poppins-light e-font-12 ">
                                                                    <a className="color-primary-color e-poppins-regular text-decoration-underline text-underline-offset-4 cursor-pointer"
                                                                        onClick={_openUTRInstructionModal}>
                                                                        Follow these instructions
                                                                    </a>
                                                                    {' '}
                                                                    to initiate NEFT/RTGS transfer and generate the UTR number
                                                                </p>
                                                            </div>
                                                        }


                                                </div>

                                            }
                                            <div className='d-sm-flex justify-content-center mt-4 pt-2'>
                                                <SecondaryButton label="Cancel"
                                                    className="me-3 color-black w-mobile-100"
                                                    cancel="modal" />

                                                <GradientButton label={props.redeem === true ?
                                                    props.data?.generate_today === false ?
                                                        `Schedule ${props.data.order_type}`
                                                        : 'Approve'
                                                    : props.data?.generate_today === false ?
                                                        `Schedule ${props.data.order_type}`
                                                        : selectedPaymentMethod === 1 ?
                                                            'Pay with UPI'
                                                            : 'Net banking'
                                                }
                                                    className="padding-64px-lr padding-14px-tb w-mobile-100 mt-sm-0 mt-3"
                                                    loading={apiLoader}
                                                    handleClick={_initiatePayment} />
                                            </div>
                                            <div className='d-flex justify-content-center mt-4'>
                                                {
                                                    seconds === 0 ?
                                                        <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-pointer mb-0 e-forgot-pin position-relative w-max-content'
                                                            onClick={_handleResendOTP}>Resend OTP</p>
                                                        :
                                                        <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-not-allowed mb-0 '>
                                                            Resend OTP in <span className="color-black">{seconds}s</span>
                                                        </p>
                                                }
                                            </div>

                                        </div>
                                    ) :
                                        renderedScreen === 1 ? (
                                            <Fragment>
                                                {/* <Icon icon="close"
                                        size={24}
                                        data-bs-dismiss="modal"
                                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`} /> */}

                                                <button id="close-modal"
                                                    data-bs-dismiss="modal"
                                                    className="d-none"></button>
                                                <div className="text-center">
                                                    {/* <img src={require("../../Assets/Images/explore/confirm-payment.png")}
                                            alt="Confirm payment"
                                            width={72}
                                            height={72} /> */}
                                                    <p className="color-eerie-black e-poppins-medium e-font-18 mb-2 mt-3">Transaction initiated, awaiting payment from UPI app</p>

                                                    <p className="color-black e-font-28 e-inter-medium ">
                                                        <span className="e-font-14 vertical-sup">₹</span>
                                                        {props.validUser === true ? props.data.summary.amount.toLocaleString('en-IN') : props.data.order_amount.toLocaleString('en-IN')}
                                                    </p>
                                                    <div className="d-flex justify-content-center">
                                                        <div className="bg-ghost-white border-radius-16px px-5 py-2 w-fit-content">
                                                            <p className="e-font-14 e-poppins-medium mb-0">Approve payment within</p>
                                                            <p className="e-font-24 e-poppins-regular mb-0">
                                                                {`${upiTimerSecond !== 600 ? '0' : ''}${Math.floor(upiTimerSecond / 60)} : ${upiTimerSecond % 60 < 10 ? '0' : ''}${upiTimerSecond % 60}`}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-4">
                                                    <h6 className="mb-3 ">UPI Payment Instructions</h6>
                                                    <ul className="ps-3">
                                                        <li className="e-font-14 e-poppins-regular color-outer-space mb-2">
                                                            Open your UPI app & confirm the payment via registered bank account with mt wealth.
                                                        </li>
                                                        <li className="e-font-14 e-poppins-regular color-outer-space  mb-2">
                                                            You may close this window after approving the payment in UPI app.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </Fragment>
                                        ) :
                                            (
                                                <PaymentStatus title={props.redeem === true ? REDEEM_SUCCESS_TITLE : paymentStatus === true ? SUCCESS_TITLE : FAILED_TITLE}
                                                    content={props.redeem === true ? REDEEM_SUCCESS_MESSAGE : paymentStatus === true ? SUCCESS_MESSAGE : FAILED_MESSAGE}
                                                    icon={paymentStatus === true ? successIcon : failureIcon} />
                                            )
                                }
                            </>
                    }

                </div>
            </div>
        </div>
    )
}

export default PendingPayments